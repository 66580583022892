<template>
  <v-dialog no-click-animation v-model="dialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition" eager>
    <v-card>
      <v-container class="pa-5 pt-0">
        <v-row justify="center">
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-row>
              <v-col class="pb-0">
                <div>
                  <the-nav-bar title="Filtrar Propostas" @back="close"></the-nav-bar>
                  <div class="header-height"></div>
                  <v-container class="pa-5 pt-5">
                    <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
                      <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
                    </div>
                    <v-row v-else>
                      <v-col cols="12">
                        <v-row justify="center">
                          <v-col cols="12">
                            <validation-observer ref="form" @submit.prevent="submit()" tag="form">
                              <base-text-field
                                validationMode="aggressive"
                                id="nomeNrProposta"
                                name="Nome Titular ou Nº Proposta"
                                v-model="form.nomeNrProposta"
                                label="Nome Titular ou Nº Proposta"
                                clearable
                                outlined
                              ></base-text-field>

                              <base-wrapper-dialog-options :key="1" headerTitle="Status" :options="dataStatus[tab]" @select="setStatus" value-key="nome" label-key="nome">
                                <template v-slot:activador="{ on }">
                                  <base-text-field
                                    :loading="false"
                                    readonly
                                    id="etapa"
                                    placeholder="Clique aqui para selecionar"
                                    name="Status"
                                    v-on="on"
                                    :value="form.etapa"
                                    label="Status"
                                    outlined
                                    append-icon="mdi-chevron-right"
                                  ></base-text-field>
                                </template>
                              </base-wrapper-dialog-options>
                              <async-entidades :fullList="true" :key="'c_entidade-0'" :getDataOnMount="false" :loadEntidades="dialog" v-slot="{ loading: loadingEntidades, data }">
                                <base-wrapper-dialog-list
                                  :key="'d_entidade-0'"
                                  headerTitle="Entidade"
                                  :options="data"
                                  label-key="id"
                                  value-key="id"
                                  :loading="loadingEntidades"
                                  return-object
                                  @select="$set(form, 'entidade', $event.id)"
                                >
                                  <template v-slot:activador="{ on }">
                                    <base-text-field
                                      inputmode="text"
                                      v-on="on"
                                      readonly
                                      id="entidade"
                                      name="Entidade"
                                      placeholder="Selecione"
                                      label="Entidade"
                                      outlined
                                      :value="form.entidade"
                                      append-icon="mdi-chevron-right"
                                      :loading="loadingEntidades"
                                    ></base-text-field>
                                  </template>
                                </base-wrapper-dialog-list>
                              </async-entidades>

                              <async-operadoras :key="'c_operadora-0'" :getDataOnMount="false" :loadOperadoras="dialog" v-slot="{ loading: loadingOperadoras, data }">
                                <base-wrapper-dialog-list
                                  :key="'d_operadora-0'"
                                  headerTitle="Operadoras"
                                  :options="data"
                                  label-key="nome"
                                  value-key="id"
                                  :loading="loadingOperadoras"
                                  return-object
                                  @select="$set(form, 'operadora', $event.nome)"
                                >
                                  <template v-slot:activador="{ on }">
                                    <base-text-field
                                      inputmode="text"
                                      v-on="on"
                                      readonly
                                      id="operadora"
                                      name="Operadora"
                                      placeholder="Selecione"
                                      label="Operadora"
                                      outlined
                                      :value="form.operadora"
                                      append-icon="mdi-chevron-right"
                                      :loading="loadingOperadoras"
                                    ></base-text-field>
                                  </template>
                                </base-wrapper-dialog-list>
                              </async-operadoras>

                              <v-row>
                                <v-col cols="12">
                                  <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">
                                    Data Proposta
                                  </h2>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="6" lg="6" xl="6">
                                  <validation-provider v-slot="{ errors }" name="dataInicio" vid="dataInicio">
                                    <base-text-field
                                      :error-messages="errors"
                                      @blur="debouncedValidateDataVigencia"
                                      :value="form.dataInicio"
                                      rules="realDate"
                                      id="dataInicio"
                                      name="Data de Inicio Vigência"
                                      label="Data de Inicio Vigência"
                                      placeholder="DD/MM/AAAA"
                                      v-mask="'##/##/####'"
                                      inputmode="numeric"
                                      clearable
                                      v-model="form.dataInicio"
                                      outlined
                                    ></base-text-field>
                                  </validation-provider>
                                </v-col>
                                <v-col cols="12" md="6" lg="6" xl="6">
                                  <validation-provider v-slot="{ errors }" name="dataFim" vid="dataFim">
                                    <base-text-field
                                      @blur="debouncedValidateDataVigencia"
                                      :error-messages="errors"
                                      :value="form.dataFim"
                                      rules="requiredif:dataInicio|realDate"
                                      id="dataFim"
                                      name="Data de Fim Vigência"
                                      label="Data de Fim Vigência"
                                      placeholder="DD/MM/AAAA"
                                      v-mask="'##/##/####'"
                                      inputmode="numeric"
                                      clearable
                                      v-model="form.dataFim"
                                      outlined
                                    ></base-text-field>
                                  </validation-provider>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col cols="12">
                                  <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">
                                    Intervalo de Preço
                                  </h2>
                                </v-col>
                              </v-row>
                              <v-row no-gutters>
                                <v-col md="2" sm="2">
                                  <v-text-field v-model="price[0]" id="rangeMin" class="mt-0 pt-0" hide-details single-line type="number"></v-text-field>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col md="2" sm="2">
                                  <v-text-field v-model="price[1]" id="rangeMax" class="mt-0 pt-0" hide-details single-line type="number"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="1" style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0">
                                  <v-range-slider class="rangePrice" v-model="price" :max="24000" :min="0" :step="1"></v-range-slider>
                                </v-col>
                              </v-row>
                              <v-btn large type="submit" elevation="10" block color="primary" class="text-none mt-5 mb-4">Filtrar</v-btn>
                              <v-btn large type="submit" elevation="10" block color="secondary" class="text-none mt-5 mb-4" @click.prevent="limparFiltros">Limpar Filtro</v-btn>
                            </validation-observer>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<style>
.rangePrice .v-input__control {
  height: 50px !important;
}
.rangePrice .v-input__control .v-input__slot {
  margin-top: auto;
}
</style>
<script>
import BaseCardBeneficiary from "@/components/BaseCardBeneficiary.vue";
import BaseWrapperDialogOptions from "@/components/BaseWrapperDialogOptions.vue";
import BaseWrapperDialogList from "@/components/BaseWrapperDialogList.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import TheNavBar from "@/components/TheNavBar.vue";
import { mapState, mapActions } from "vuex";
import filters from "@/filters";
import AsyncEntidades from "@/components/AsyncEntidades.vue";
import AsyncOperadoras from "@/components/AsyncListOperadoras.vue";

const _ = require("lodash");

export default {
  name: "DialogFiltroPropostas",
  components: {
    TheNavBar,
    BaseTextField,
    BaseWrapperDialogOptions,
    AsyncEntidades,
    BaseWrapperDialogList,
    AsyncOperadoras,
  },
  data() {
    return {
      price: [0, 24000],
      filtros: {},
      loading: false,
      form: {},
      dataStatus: {
        em_andamento: [
          {
            idsEtapa: [
              "basicos",
              "corretora",
              "operadora",
              "plano",
              "vigencia",
              "dados_titular_contratacao",
              "dados_responsavel_contratacao",
              "dados_profissionais",
              "dependentes_contratacao",
              "produto_opcional",
              "dados_financeiros",
              "anexo",
              "preenchido",
            ],
            status: "EM_DIGITACAO",
            nome: "Em Digitação",
          },
          {
            idsEtapa: ["aguardando_assinatura"],
            status: "EM_DIGITACAO",
            nome: "Aguardando Assinatura",
          },
          {
            idsEtapa: ["assinatura_recusada"],
            status: "EM_DIGITACAO",
            nome: "Assinatura Recusada",
          },
          {
            idsEtapa: ["contrato_assinado"],
            status: "EM_DIGITACAO",
            nome: "Contrato Assinado",
          },
          {
            idsEtapa: ["pronta_transmissao"],
            status: "EM_DIGITACAO",
            nome: "Pronta Transmissão",
          },
        ],
        em_aprovacao: [
          {
            idsEtapa: ["devolvida", "aguardando_assinatura_adendo", "adendo_assinado", "adendo_recusado"],
            status: "DEVOLVIDA",
            nome: "Devolvida",
          },
          {
            idsEtapa: ["em_analise"],
            status: "EM_ANALISE",
            nome: "Em análise",
          },
          {
            idsEtapa: ["fila_transmissao", "erro_transmissao"],
            status: "FILA_TRANSMISSAO",
            nome: "Fila Transmissão",
          },
        ],
        historico: [
          {
            idsEtapa: ["cancelada"],
            status: "CANCELADA",
            nome: "Cancelada",
          },
          {
            idsEtapa: ["implantada"],
            status: "IMPLANTADA",
            nome: "Implantada",
          },
          {
            idsEtapa: ["finalizada"],
            status: "FINALIZADA",
            nome: "Finalizada",
          },
          {
            idsEtapa: ["nao_recomendada"],
            status: "NAO_RECOMENDADA",
            nome: "Não Recomendada",
          },
          {
            idsEtapa: ['finalizada'],
            status: 'FINALIZADA',
            nome: 'Finalizada',
          },
        ],
      },
    };
  },
  props: {
    tab: {
      default: "em_andamento",
    },
    dialog: {
      default: false,
    },
  },
  created() {
    this.debouncedValidateDataVigencia = _.debounce(this.validateDataVigencia, 350);
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
  },
  methods: {
    validateDataVigencia() {
      const dataInicio = this.form.dataInicio && this.form.dataInicio.split("/");
      const dataFim = this.form.dataFim && this.form.dataFim.split("/");

      if (this.$refs.form.errors.dataInicio && !this.$refs.form.errors.dataInicio.length && this.$refs.form.errors.dataFim && !this.$refs.form.errors.dataFim.length) {
        let dataInicioDate;
        let dataFimDate;

        if (dataInicio && dataInicio.length === 3) dataInicioDate = new Date(dataInicio[2], parseInt(dataInicio[1], 10) - 1, dataInicio[0]);
        if (dataFim && dataFim.length === 3) dataFimDate = new Date(dataFim[2], parseInt(dataFim[1], 10) - 1, dataFim[0]);

        if (typeof dataInicioDate === "object") dataInicioDate = dataInicioDate.getTime();
        if (typeof dataFimDate === "object") dataFimDate = dataFimDate.getTime();

        if (dataInicioDate && dataFimDate && dataInicioDate > dataFimDate) {
          this.$root.$snackBar.open({
            color: "error",
            message: "Data de inicio de vigência não pode ser maior que o fim",
          });

          this.form.dataInicio = "";
          this.form.dataFim = "";
        }
      }
    },
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.isLoading = true;
      try {
        const campos = {};
        Object.assign(campos, this.form);

        let etapa = {};

        if (campos.etapa) etapa = this.dataStatus[this.tab].find((el) => el.nome === campos.etapa) || {};

        if (etapa.idsEtapa) campos.etapa = { idsEtapa: etapa.idsEtapa };

        this.$emit("filtrarpropostas", { campos, rangePreco: this.price });
      } catch (error) {
        this.$root.$snackBar.open({ color: "error", message: error.message });
      } finally {
        this.isLoading = false;
      }
    },
    setStatus(event) {
      this.$set(this.form, "etapa", event.nome);
    },
    limparFiltros() {
      this.form = {};
      this.price = [0, 24000];
    },
    close() {
      this.$emit("close", false);
    },
  },
};
</script>
