<template>
  <fragment>
    <slot :data="operadoras" :loading="loading"></slot>
  </fragment>
</template>

<script>
import catalogoService from '@/services/catalogoService';

export default {
  name: 'AsyncOperadoras',
  props: {
    getDataOnMount: {
      type: Boolean,
      default: true,
    },
    loadOperadoras: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      operadoras: [],
    };
  },
  watch: {
    async loadOperadoras() {
      if (this.loadOperadoras) {
        await this.getData();
      }
    },
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const data = await catalogoService.getOperadoras();
        this.operadoras = data;
        this.$emit('success', data);
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao buscar dados, tente novamente mais tarde',
        });
        this.$emit('error');
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    if (this.getDataOnMount) {
      await this.getData();
    }
  },
};

</script>

<style>

</style>
