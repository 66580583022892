<template>
  <div>
    <the-toolbar title="Propostas">
      <v-btn icon @click="handleReload">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
      <v-btn icon @click="openFiltro">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </the-toolbar>

    <v-tabs v-model="tab" background-color="#fff" color="primary" grow @change="handleContentTabs">
      <v-tab :class="{ 'text-capitalize': true, 'tab-size': $vuetify.breakpoint.xs }">Em Andamento</v-tab>
      <v-tab :class="{ 'text-capitalize': true, 'tab-size': $vuetify.breakpoint.xs }">Em aprovação</v-tab>
      <v-tab :class="{ 'text-capitalize': true, 'tab-size': $vuetify.breakpoint.xs }">Histórico</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <div v-if="tab === 2">
      <v-tabs background-color="#fff" color="primary" v-model="tabThreeActive" grow @change="getContentTabThree">
        <v-tab v-for="(tab, index) in tabsThree" :class="{ 'text-capitalize': true, 'tab-size': $vuetify.breakpoint.xs }" :key="index">
          {{ tab.name }}
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>
    <div v-if="tab === 1">
      <v-tabs background-color="#fff" color="primary" v-model="tabTwoActive" grow @change="getContentTabTwo">
        <v-tab v-for="(tab, index) in tabsTwo" :class="{ 'text-capitalize': true, 'tab-size': $vuetify.breakpoint.xs }" :key="index">
            {{ tab.name }}
          <v-badge
          style="margin-left: 5px;"
            v-if="tab.name === 'Devolvida'"
             :color="notification > 0
              ? 'warning'
              : 'transparent'
          "
            :content="notification"
          ></v-badge>
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>
    <dialog-filtro-propostas :tab="tabRef[tab]" :dialog="dialogFiltro" @filtrarpropostas="filtrarPropostas" @close="dialogFiltro = false"></dialog-filtro-propostas>
    <v-tabs-items v-model="tab" class="pb-10" @change="handleContentTabs">
      <v-tab-item>
        <div v-if="loadingTabOne" class="d-flex justify-center mt-10 pa-10">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </div>
        <v-container class="pa-5 pt-2" v-else>
          <v-row no-gutters>
            <v-col>
              <p class="text-center ma-2 subtitle-2 grey--text">{{ contentTabOne.length }} registro(s) encontrados(s)</p>
            </v-col>
          </v-row>
          <v-row v-if="contentTabOne.length">
            <v-col cols="12" md="6" xl="4" v-for="proposta in contentTabOne" :key="proposta.id">
              <div @click="getDatas(proposta.guid)" class="cursor-pointer">
                <base-card-details-proposta
                  :numero="proposta.nrProposta || '--'"
                  :nome="proposta.titular ? proposta.titular.nome : ''"
                  :operadora="proposta.plano.nomeExibicaoOperadora || proposta.plano && proposta.plano.operadora"
                  :entidade="proposta.titular && proposta.titular.entidade"
                  :status="proposta.etapa.titulo"
                  :etapa="proposta.etapa || {}"
                  :tempoParaTransmissao="proposta.vigencia || {}"
                  :permitidoTransmissao="proposta.vigencia && proposta.vigencia.permitidoTransmissao"
                  :valor="Number(proposta.valorTotalProposta)"
                  :administradora="proposta.administradora"
                  :valorTotalOdontoAdicional="proposta.plano.valorTotalOdontoAdicional"
                ></base-card-details-proposta>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <div v-if="loadingTabTwo" class="d-flex justify-center mt-10 pa-10">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </div>
        <v-container class="pa-5 pt-2" v-else>
          <v-row no-gutters>
            <v-col>
              <p class="text-center ma-2 subtitle-2 grey--text">{{ contentTabTwo.length }} registro(s) encontrados(s)</p>
            </v-col>
          </v-row>
          <v-row v-if="contentTabTwo.length">
            <v-col cols="12" md="6" xl="4" v-for="proposta in contentTabTwo" :key="proposta.id">
              <div @click="getDatas(proposta.guid)" class="cursor-pointer">
                <base-card-details-proposta
                  :numero="proposta.nrProposta || '--'"
                  :nome="proposta.titular ? proposta.titular.nome : ''"
                  :operadora="proposta.plano.nomeExibicaoOperadora || proposta.plano && proposta.plano.operadora"
                  :entidade="proposta.titular && proposta.titular.entidade"
                  :status="proposta.etapa.titulo"
                  :etapa="proposta.etapa || {}"
                  :tempoParaTransmissao="proposta.vigencia || {}"
                  :associado="associadoTratando(proposta.usuarioTratando)"
                  :permitidoTransmissao="proposta.vigencia && proposta.vigencia.permitidoTransmissao"
                  :administradora="proposta.administradora"
                  :valor="Number(proposta.valorTotalProposta)"
                  :valorTotalOdontoAdicional="proposta.plano.valorTotalOdontoAdicional"
                ></base-card-details-proposta>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <div v-if="loadingTabThree" class="d-flex justify-center mt-10 pa-10">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </div>
        <v-container class="pa-5 pt-2" v-else>
          <v-row no-gutters>
            <v-col>
              <p class="text-center ma-2 subtitle-2 grey--text">{{ contentTabThree.length }} registro(s) encontrados(s)</p>
            </v-col>
          </v-row>
          <v-row v-if="contentTabThree.length">
            <v-col cols="12" md="6" xl="4" v-for="proposta in contentTabThree" :key="proposta.id">
              <div @click="getDatas(proposta.guid)" class="cursor-pointer">
                <base-card-details-proposta
                  :numero="proposta.nrProposta || '--'"
                  :nome="proposta.titular ? proposta.titular.nome : ''"
                  :operadora="proposta.plano.nomeExibicaoOperadora || proposta.plano && proposta.plano.operadora"
                  :entidade="proposta.titular && proposta.titular.entidade"
                  :status="proposta.etapa.titulo"
                  :etapa="proposta.etapa || {}"
                  :tempoParaTransmissao="proposta.vigencia || {}"
                  :permitidoTransmissao="null"
                  :valor="Number(proposta.valorTotalProposta)"
                  :administradora="proposta.administradora"
                  :valorTotalOdontoAdicional="proposta.plano.valorTotalOdontoAdicional"
                ></base-card-details-proposta>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <v-btn :color="$store.getters.getColor('primary')" fixed dark right bottom fab class="mt-10" style="bottom: 10px" @click="redirecionarCriaProposta">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import propostaService from '@/services/propostaService';
import BaseCardDetailsProposta from '@/components/BaseCardDetailsProposta.vue';
import TheToolbar from '@/components/TheToolbar.vue';
import { format, parse } from 'date-fns';
import DialogFiltroPropostas from './DialogFiltroPropostas.vue';

export default {
  name: 'Propostas',

  components: { TheToolbar, BaseCardDetailsProposta, DialogFiltroPropostas },
  data() {
    return {
      dialogFiltro: false,
      tab: null,
      loadingTabOne: false,
      loadingTabTwo: false,
      loadingTabThree: false,
      tabThreeActive: 0,
      tabTwoActive: 0,
      tabsTwo: [
        {
          id: ['EM_ANALISE', 'FILA_TRANSMISSAO'],
          name: 'Em Analise',
        },
        {
          id: ['DEVOLVIDA'],
          name: 'Devolvida',
        },
      ],
      tabsThree: [
        {
          id: ['IMPLANTADO', 'IMPLANTADA', 'FINALIZADA'],
          name: 'Implantado',
        },
        {
          id: ['CANCELADO', 'CANCELADA'],
          name: 'Cancelado',
        },
        {
          id: ['NAO_RECOMENDADA', 'CANCELADA_PRE_VIGENCIA'],
          name: 'Não Implantada',
        }
      ],
      tabsContent: [],
      tabRef: ['em_andamento', 'em_aprovacao', 'historico'],
      contentTabOne: [],
      contentTabTwo: [],
      contentTabThree: [],
      notification: 0,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.profile,
    }),
  },
  methods: {
    ...mapActions({
      setBlnContinuar: 'cadastroProposta/setBlnContinuar',
    }),
    redirecionarCriaProposta() {
      this.setBlnContinuar({ flag: false, page: 0 });
      this.$router.push({ name: 'areaLogada.criarProposta' });
    },
    filtrarPropostas(filtros) {
      this.dialogFiltro = false;

      const keysFiltros = Object.keys(filtros.campos);

      const objFiltros = {
        valorMinimo: filtros.rangePreco[0],
        valorMaximo: filtros.rangePreco[1],
      };

      keysFiltros.forEach((key) => {
        if (filtros.campos[key]) {
          if (key.includes('data')) {
            objFiltros[key] = format(parse(filtros.campos[key], 'dd/mm/yyyy', new Date()), 'yyyy-mm-dd');
          } else {
            objFiltros[key] = filtros.campos[key];
          }
        }
      });

      this.filtros = objFiltros;
      try {
        this.handleContentTabs(this.tab, true /** true = force reload */);
      } catch (err) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao buscar dados, tente novamente mais tarde',
        });
      }
    },
    getDatas(guid) {
      this.$router.push({ name: 'areaLogada.dadosProposta', params: { guid } });
    },
    async getContentTabOne() {
      try {
        this.loadingTabOne = true;
        const data = await propostaService.lista({
          filtros: this.filtros,
          statusAgrupado: 'em_andamento',
          cpf: this.user.cpf,
        });

        this.contentTabOne = this.orderByChangeDate(data);
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao buscar dados, tente novamente mais tarde',
        });
      } finally {
        this.loadingTabOne = false;
      }
    },
    async getContentTabTwo() {
      try {
        this.loadingTabTwo = true;
        let data = await propostaService.lista({
          filtros: this.filtros,
          statusAgrupado: 'em_aprovacao',
          cpf: this.user.cpf,
        });
        this.notification = 0;
        // eslint-disable-next-line consistent-return
        data.forEach((e) => {
          if (e.status === "DEVOLVIDA") {
            return this.notification++;
          }
        });
        data = data.filter((d) => this.tabsTwo[this.tabTwoActive].id.includes(d.status));
        this.contentTabTwo = this.orderByChangeDate(data);
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao buscar dados, tente novamente mais tarde',
        });
      } finally {
        this.loadingTabTwo = false;
      }
    },
    async getContentTabThree() {
      try {
        this.loadingTabThree = true;
        let data = await propostaService.lista({
          filtros: this.filtros,
          statusAgrupado: 'historico',
          cpf: this.user.cpf,
        });
        data = data.filter((d) => this.tabsThree[this.tabThreeActive].id.includes(d.status));
        this.contentTabThree = this.orderByChangeDate(data);
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Erro ao buscar dados, tente novamente mais tarde',
        });
      } finally {
        this.loadingTabThree = false;
      }
    },
    async handleReload() {
      switch (this.tab) {
      case 0: {
        await this.getContentTabOne();
        break;
      }
      case 1: {
        await this.getContentTabTwo();
        break;
      }
      case 2: {
        await this.getContentTabThree();
        break;
      }
      default: {
        break;
      }
      }
    },
    async handleContentTabs(value, reloadFilter) {
      switch (value) {
      case 0: {
        if (!this.contentTabOne.length || reloadFilter) {
          await this.getContentTabOne();
        }
        break;
      }
      case 1: {
        if (!this.contentTabTwo.length || reloadFilter) {
          await this.getContentTabTwo();
        }
        break;
      }
      case 2: {
        if (!this.contentTabThree.length || reloadFilter) {
          await this.getContentTabThree();
        }
        break;
      }
      default: {
        break;
      }
      }
    },
    openFiltro() {
      this.dialogFiltro = true;
    },
    orderByChangeDate(array) {
      array.sort((a, b) => new Date(b.dataAlteracao) - new Date(a.dataAlteracao));
      return array;
    },
    associadoTratando(usuarioTratando) {
      if (usuarioTratando && usuarioTratando.id && usuarioTratando.id !== this.user.id) {
        return usuarioTratando.nome;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.tab-size {
  font-size: 0.8rem;
}
</style>

